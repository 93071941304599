@media (max-width: 768px) { 

  .map-container {
    min-height: 400px;
    width: 100%;
  }

}

@media (min-width: 768px) { 

  .map-container {
    min-height: 100%;
    width: 100%;
  }

}