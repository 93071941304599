@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Dosis:200,400,500,600");

.help-block { 
    font-size: 12px;
    color: #ff0000;
    background-color: rgba(255, 200, 200, 10);
    text-align: center;
}

.Login {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.Login-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px;
}

.background {
    width: 400px !important;
    background-color: white;
    padding: 2em !important;
}

.login-title {
    font-family: Dosis,sans-serif;
    font-size: 1.23047rem;
    font-weight: 400;
}

.form-group {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
    -webkit-box-align: center;
            align-items: center;
    margin-bottom: 0; 
}

@media (min-width: 650px ) {
    .Login {
        background-position: center center;
    }

    .Login-content {
        justify-content: center;
    }
}
